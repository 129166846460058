<template>
  <div>
    <v-card v-if="loaded" class="ma-0" min-height="500">
      <v-card-title class="primary white--text py-0">
        <slot name="title"> {{ user.name }} </slot>
        <v-spacer />
      </v-card-title>
      <v-divider />
      <div :id="`wrapper${user}`" v-if="dates.length">
        <div :id="`chart-line${user}`">
          <apexchart type="line" :height="200" :options="options1" :series="series1"></apexchart>
        </div>
        <div :id="`chart-bar${user}`"><apexchart type="bar" :height="300" :options="options2" :series="series2"></apexchart></div>
      </div>
    </v-card>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    period: Object,
    user: Object,
    data: Array,
    dataP: Array,
  },
  data() {
    return {
      selectedData: {},
      dialog: false,
      options1: {},
      options2: {},
      series1: [],
      series2: [],
      dates: [],

      res: [],
      loaded: false,
    };
  },
  created() {
    this.makeGraph(this.user.id);
  },
  computed: {},
  watch: {},
  methods: {
    makeGraph(user) {
      const c = this.period.value == "1M" ? 30 : this.period.value == "2M" ? 60 : this.period.value == "3M" ? 90 : 0;
      let res = [];
      let data = [...this.data].filter((d) => d.user_id == user || user == -1);
      let dataP = [...this.dataP].filter((d) => d.user_id == user || user == -1);
      let works = [];
      let dones = [];
      let lates = [];
      let result = [];
      let resultP = [];
      for (let i = 0; i < c; i++) {
        let date_ = new Date(this.period.start).addDays(i);
        let dd = new Date(date_.date).addDays(-c);
        this.dates.push(date_.date);
        let work = data.filter((d) => {
          if ((new Date(d.date_start) <= date_ && (!d.date_done || new Date(d.date_done) >= date_)) || d.date_done === date_.date) {
            return true;
          }
          return false;
        });
        let done = data.filter((d) => {
          if (d.date_done && d.date_done == date_.date) {
            return true;
          }
          return false;
        });
        let doneP = dataP.filter((d) => {
          if (d.date_done && d.date_done == dd.date) {
            return true;
          }
          return false;
        });
        let workP = dataP.filter((d) => {
          if ((new Date(d.date_start) <= dd && (!d.date_done || new Date(d.date_done) >= dd)) || d.date_done === dd.date) {
            return true;
          }
          return false;
        });
        let late = work.filter((d) => new Date(d.date_end) < date_).length;
        //   let late = work.filter((d) => new Date(d.date_end) < new Date(d.date_done)).length;
        works.push(work.length - late);
        lates.push(late);
        dones.push(-done.length);
        result.push(((work.length ? done.length / work.length : 0) * 100).toFixed(0));
        resultP.push(((workP.length ? doneP.length / workP.length : 0) * 100).toFixed(0));
        res.push({
          date: date_.date,
          task_work_count: work.length,
          task_work: work,
          task_done_count: done.length,
          task_done: done,
          late_count: late,
        });
      }
      this.options1 = {
        chart: { id: "chart-line-" + user, group: "social-" + user, height: 250, type: "line", toolbar: { show: !true }, zoom: { enabled: !true } },
        stroke: { width: [3, 2, 4] },
        dataLabels: { enabled: false },
        labels: this.dates,
        xaxis: { position: "top", labels: { show: false } },
        yaxis: {
          seriesName: "result",
          axisTicks: { show: true },
          axisBorder: { show: true, color: "#008FFB" },
          labels: { style: { colors: "#008FFB" } },
          title: { text: "Эффективность", style: { color: "#008FFB" } },
          tooltip: { enabled: true },
        },
      };
      this.options2 = {
        chart: {
          id: "chart-bar-" + user,
          group: "social-" + user,
          height: 150,
          stacked: true,
          toolbar: { show: !true },
          zoom: { enabled: !true },
          events: { dataPointSelection: this.onDataPointSelection },
        },
        dataLabels: { enabled: false },
        labels: this.dates,
        xaxis: {
          type: "datetime",
          labels: {
            rotate: -90,
            rotateAlways: false,
            formatter: function (value, timestamp, opts) {
              const date = new Date(value);
              const options = { day: "numeric", month: "short" };
              return date.toLocaleDateString("ru-RU", options);
            },
          },
        },
        yaxis: {
          seriesName: "works",
          axisTicks: { show: !true },
          axisBorder: { show: true, color: "#00E396" },
          labels: { style: { colors: "#00E396" } },
          title: { text: "Колличество задач", style: { color: "#00E396" } },
        },
      };
      this.options2.xaxis.categories = this.dates;
      // console.log(dataP, resultP);
      this.series1 = [
        { name: "Текущий", type: "line", data: result },
        { name: "Предыдущий", type: "line", data: resultP },
      ];
      this.series2 = [
        { name: "Текущие", type: "column", data: works },
        { name: "Просроченые", color: "#EE6D7A", type: "column", data: lates },
        { name: "Выполненые", color: "#43A047", type: "column", data: dones },
      ];
      this.res = [...res];
      this.loaded = true;
    },
    onDataPointSelection(event, chartContext, { dataPointIndex, seriesIndex }) {
      // Получение данных выбранного столбца
      console.log("onDataPointSelection", dataPointIndex, seriesIndex);
      const selectedCategory = this.options2.xaxis.categories[dataPointIndex];
      const selectedValue = this.series2[seriesIndex].data[dataPointIndex];
      console.log(this.res[dataPointIndex]);
      // Сохранение данных для отображения в диалоговом окне
      this.selectedData = {
        category: selectedCategory,
        value: selectedValue,
      };

      // Показ диалогового окна
      this.dialog = true;
      this.$emit("showDetail", this.res[dataPointIndex]);
    },
  },
};
</script>
